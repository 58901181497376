/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  GET_LIST_CADDY_GROUP,
  GET_DETAIL_CADDY_GROUP,
  UPDATE_CADDY_GROUP,
  ADD_NEW_CADDY_GROUP,
  DELETE_CADDY_GROUP,
  ACTIVE_CADDY_GROUP,
  DEACTIVE_CADDY_GROUP,
  GET_LIST_CADDY,
  ADD_NEW_CADDY,
  UPDATE_CADDY,
  GET_DETAIL_CADDY,
  ACTIVE_CADDY,
  DEACTIVE_CADDY,
  DELETE_CADDY,
  SEARCH_CADDY,
  API_UCA04,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListCaddyGroup(data) {
  return fetch(ROOT + GET_LIST_CADDY_GROUP, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailCaddyGroup(data) {
  return fetch(ROOT + GET_DETAIL_CADDY_GROUP, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateCaddyGroup(data) {
  return fetch(ROOT + UPDATE_CADDY_GROUP, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewCaddyGroup(data) {
  return fetch(ROOT + ADD_NEW_CADDY_GROUP, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteCaddyGroup(data) {
  return fetch(ROOT + DELETE_CADDY_GROUP, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function activeCaddyGroup(data) {
  return fetch(ROOT + ACTIVE_CADDY_GROUP, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deactiveCaddyGroup(data) {
  return fetch(ROOT + DEACTIVE_CADDY_GROUP, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListCaddy(data) {
  return fetch(ROOT + GET_LIST_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewCaddy(data) {
  return fetch(ROOT + ADD_NEW_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailCaddy(data) {
  return fetch(ROOT + GET_DETAIL_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateCaddy(data) {
  return fetch(ROOT + UPDATE_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function activeCaddy(data) {
  return fetch(ROOT + ACTIVE_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deactiveCaddy(data) {
  return fetch(ROOT + DEACTIVE_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteCaddy(data) {
  return fetch(ROOT + DELETE_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function searchCaddy(data) {
  return fetch(ROOT + SEARCH_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UCA04(data) {
  return fetch(ROOT + API_UCA04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const caddySetting = {
  getListCaddyGroup,
  getDetailCaddyGroup,
  updateCaddyGroup,
  addNewCaddyGroup,
  deleteCaddyGroup,
  activeCaddyGroup,
  deactiveCaddyGroup,
  getListCaddy,
  addNewCaddy,
  updateCaddy,
  getDetailCaddy,
  activeCaddy,
  deactiveCaddy,
  deleteCaddy,
  searchCaddy,
  api_UCA04,
}
