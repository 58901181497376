import { booking } from '@/api/booking'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, length, max, integer, double, email,
} from '@validations'
import vSelect from 'vue-select'
import { caddySetting } from '@/api/caddy-setting'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'

export default {
  name: 'DetailCaddy',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Treeselect,
    'confirm-dialog': ConfirmDialog,
    'delete-dialog': DeleteDialog,
  },
  props: {
    listCourse: {
      type: Array,
      required: true,
    },
    listSex: {
      type: Array,
      required: true,
    },
    listTypeOfContract: {
      type: Array,
      required: true,
    },
    listCaddyPosition: {
      type: Array,
      required: true,
    },
    listMaritalStatus: {
      type: Array,
      required: true,
    },
    listCaddyGroup: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      currentLang: this.$i18n.locale,
      listCaddyStatus: [
        {
          KeyValue: true,
          KeyName: 'Ngừng hoạt động',
        },
        {
          KeyValue: false,
          KeyName: 'Đang hoạt động',
        },
      ],
      bloodList: ['O', 'A', 'B', 'AB'],
      listClotherSize: ['S', 'M', 'L', 'XL', 'XXL'],
      detailCaddy: {},
      preview: null, // MB
      caddyId: null,
      listSexIcon: [
        {
          value: 'SEX_M',
          icon: 'ezGolf-icon-male',
        },
        {
          value: 'SEX_F',
          icon: 'ezGolf-icon-female',
        },
        {
          value: 'SEX_LGBT',
          icon: 'ezGolf-icon-other-gender',
        },
      ],
      isLoading: false,
      dataConfirm: {},
      statusAction: 'ACTIVE',
      Cashiers: [],
      yearEnd: this.dayjs().year(),
      yearStart: this.dayjs().year() - 90,
    }
  },
  computed: {
    previewImage() {
      return this.preview
    },
  },
  methods: {
    open(caddyGroupId, caddyId) {
      this.$refs.caddyForm.reset()
      this.isOpen = true
      this.caddyId = caddyId
      if (this.caddyId) {
        this.getDetailCaddy_RCA01_AC2(this.caddyId)
      } else {
        this.detailCaddy = {
          CaddyCode: null,
          CaddyName: null,
          BirthDay: this.getUTC('start', 'day'),
          PhoneNumber: null,
          RelativePhoneNumber: null,
          Email: null,
          Address1: null,
          Address2: null,
          Note: null,
          Height: null,
          Weight: null,
          ShoeSize: null,
          LockerNumber: null,
          Avatar: null,
          CourseId: [],
          CaddyGroupId: caddyGroupId,
          InActive: false,
          SexCode: this.listSex[1].KeyCode,
          MaritalStatusCode: this.listMaritalStatus[1].KeyCode,
          PositionCode: this.listCaddyPosition[0].KeyCode,
          ContractCode: this.listTypeOfContract[0].KeyCode,
          ShirtSize: 'S',
          PantsSize: 'S',
          BloodGroup: 'O',
          UserId: null,
          EmployeeCode: null  
        }
      }
      this.getListCashier_RCS01()
    },
    afterUpdateStatusCaddy(res) {
      this.$emit('afterUpdateStatusCaddy', res)
    },
    afterUpdateDetailCaddy(res) {
      this.$emit('afterUpdateDetailCaddy', res)
    },
    afterDeleteCaddy(res) {
      this.$emit('afterDeleteCaddy', res)
    },
    async getDetailCaddy_RCA01_AC2(caddyId) {
      this.isLoading = true
      // Lấy chi tiết caddy
      await caddySetting
        .getDetailCaddy({ Caddy: { Id: caddyId } })
        .then(res => {
          if (res) {
            this.detailCaddy = res.Data.Caddy
          }
        })
      this.isLoading = false
    },
    addNewCaddy_ICA01_AC4() {
      this.$refs.caddyForm.validate().then(success => {
        if (success) {
          // Thêm mới caddy
          caddySetting
            .addNewCaddy({ Caddy: this.detailCaddy })
            .then(res => {
              this.showResToast(res)
              if (res.Status == 200) {
                this.$emit('detailCaddy', this.detailCaddy.CaddyGroupId)
                this.isOpen = false
                this.afterUpdateDetailCaddy(res)
              }
            })
        }
      })
    },
    updateCaddy_UCA01_AC5() {
      this.$refs.caddyForm.validate().then(success => {
        if (success) {
          // const tmp = { ...this.detailCaddy }
          // tmp.UserId = null
          // Cập nhật caddy
          caddySetting
            .updateCaddy({ Caddy: this.detailCaddy })
            .then(res => {
              if (res.Status == 200) {
                // Cập nhật caddy
                caddySetting
                  .api_UCA04({ CaddyId: this.detailCaddy.Id, CaddyUserId: this.detailCaddy.UserId })
                  .then(res1 => {
                    this.showResToast(res)
                    if (res1.Status == 200) {
                      this.isOpen = false
                    }
                  })
                this.afterUpdateDetailCaddy(res)
              } else {
                this.showResToast(res)
              }
            })
        }
      })
    },
    uploadImage(isAddNew) {
      let file = ''
      if (isAddNew) {
        file = document.querySelector('#upload-new-image').files[0]
      } else {
        file = document.querySelector('#upload-image').files[0]
      }
      const reader = new FileReader()
      reader.onload = e => {
        const dataURI = e.target.result
        if (dataURI) {
          if (isAddNew) {
            this.addNewCaddy.Avatar = dataURI.replace('data:image/jpeg;base64,', '')
          } else {
            this.detailCaddy.Avatar = dataURI.replace('data:image/jpeg;base64,', '')
          }
        }
      }
      reader.readAsDataURL(file)
    },
    test(haha) {
      // console.log(haha)
    },

    async deleteCaddy_DCA01_AC8(caddyId) {
      // Xoá caddy
      await caddySetting
        .deleteCaddy({ Caddy: { Id: caddyId.Id } })
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.isOpen = false
            this.afterUpdateDetailCaddy(res)
          }
        })
    },
    async deactiveCaddy_UCA01_AC6(caddyId) {
      this.dataConfirm = {
        title: this.$t('golf_golf_caddy_deactive'),
        content: this.$t('golf_golf_caddy_confirm_deactive'),
      }
      this.statusAction = 'DEACTIVE'
      this.$refs.confirmDialogComponent.open({
        Id: caddyId,
        dataConfirm: this.dataConfirm,
      })
      // Ngừng hoạt động Caddy
    },
    activeCaddy_UCA01_AC7(caddyId) {
      this.dataConfirm = {
        title: this.$t('golf_golf_caddy_active'),
        content: this.$t('golf_golf_caddy_confirm_active'),
      }
      this.statusAction = 'ACTIVE'
      this.$refs.confirmDialogComponent.open({
        Id: caddyId,
        dataConfirm: this.dataConfirm,
      })
    },
    async actionDialog(data) {
      if (this.statusAction === 'ACTIVE') {
        await caddySetting
          .activeCaddy({ Caddy: { Id: data.Id } })
          .then(res => {
            this.showResToast(res)
            if (res.Status == 200) {
              this.isOpen = false
              this.afterUpdateDetailCaddy(res)
            }
          })
      } else {
        await caddySetting
          .deactiveCaddy({ Caddy: { Id: data.Id, IsForce: false } })
          .then(res => {
            this.showResToast(res)
            if (res.Status == 200) {
              this.isOpen = false
              this.afterUpdateDetailCaddy(res)
            }
          })
      }
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(async response => {
        if (response.Status === '200') {
          this.Cashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
  },
}
