import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

import vSelect from 'vue-select'
import ExcelJS from "exceljs";
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import readXlsxFile from 'read-excel-file';

import { commonServices } from '@/api/common-services'
import { caddySetting } from '@/api/caddy-setting'
import { courseSetting } from '@/api/course-setting'
var htmlToImage = require('html-to-image')
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import Treeselect from '@riophae/vue-treeselect'
import DetailCaddy from './DetailCaddy/DetailCaddy.vue'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import VueQrcode from 'vue-qrcode'

export default {
  name: 'CaddySetting',
  components: {
    DetailCaddy,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    'confirm-dialog': ConfirmDialog,
    'delete-dialog': DeleteDialog,
    Treeselect,
    VueQrcode
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      caddyGroupSelected: null,
      listSex: [],
      listTypeOfContract: [],
      listCaddyPosition: [],
      listMaritalStatus: [],
      listCaddyGroup: [],
      listCaddy: [],
      listCourse: [],
      listAge: [],
      isOpenCaddyGroupDetail: false,
      detailCaddyGroup: {},
      searchObj: {
        KeyWord: null,
        Age: null,
        CourseId: [],
        SexCode: null,
      },
      dataConfirm: {},
      statusAction: 'ACTIVE',
      listSexTree: [],
      isLoading: false,
      listCaddyGenerateQr: [],
      listHTMLGenerateQr: [],
      SelectAll: false,
      isShowGenerateQr: null  ,
      bloodList: ['O', 'A', 'B', 'AB'],
      listClotherSize: ['S', 'M', 'L', 'XL', 'XXL'],
    }
  },
  created() {
    this.init_RCAG01_AC1()
  },
  methods: {
    async init_RCAG01_AC1() {
      // Lấy danh sách tổ caddy
      this.getListCaddyGroup(true)


      await commonServices
      .getCommon({ KeyGroup: 'OTHER' })
      .then(res => {
        if (res) {
          this.isShowGenerateQr = res?.Data.find(x => x.KeyCode === 'CADDY_ALLOW_EXPORT_TO_QR') ? res?.Data.find(x => x.KeyCode === 'CADDY_ALLOW_EXPORT_TO_QR').KeyValue : null
        }
      })
      // Lấy danh sách giới tính
      await commonServices
        .getCommon({ KeyGroup: 'SEX' })
        .then(res => {
          if (res) {
            this.listSex = res.Data.filter(x => x.LangId == this.currentLang)
            this.listSexTree = res.Data.filter(x => x.LangId == this.currentLang).map(x => ({
              id: x.KeyCode,
              label: x.KeyValue,
            }))
          }
        })

      await commonServices
        .getCommon({ KeyGroup: 'RCA03_CADDY_SEARCH_BY_AGE' })
        .then(res => {
          if (res) {
            this.listAge = res.Data.filter(x => x.LangId == this.currentLang).map(x => ({
              id: x.KeyCode,
              label: x.KeyValue,
            }))
          }
        })

      // Lấy danh sách loại hợp đồng
      await commonServices
        .getCommon({ KeyGroup: 'CADDY_TCT' })
        .then(res => {
          if (res) {
            this.listTypeOfContract = res.Data.filter(x => x.LangId == this.currentLang)
          }
        })

      // Lấy danh sách vị trí
      await commonServices
        .getCommon({ KeyGroup: 'CADDY_PO' })
        .then(res => {
          if (res) {
            this.listCaddyPosition = res.Data.filter(x => x.LangId == this.currentLang)
          }
        })

      // Lấy danh sách tình trạng hôn nhân
      await commonServices
        .getCommon({ KeyGroup: 'MARITALSTATUS' })
        .then(res => {
          if (res) {
            this.listMaritalStatus = res.Data.filter(x => x.LangId == this.currentLang)
          }
        })

      // Lấy danh sách sân
      await courseSetting
        .getListCourse()
        .then(res => {
          if (res) {
            this.listCourse = res.Data.Course.filter(x => x.InActive == false).map(x => ({
              id: x.Id,
              label: x.CourseName,
            }))
          }
        })
    },
    // Lấy danh sách tổ caddy, nếu là mới vào trang, thì load thêm danh sách caddy của tổ
    async getListCaddyGroup(isFirst) {
      await caddySetting
        .getListCaddyGroup()
        .then(res => {
          if (res) {
            this.listCaddyGroup = res.Data.CaddyGroup
            if (isFirst) {
              this.getListCaddy_RCA01_AC1(this.listCaddyGroup[0].Id)
            } else {
              // this.getListCaddy_RCA01_AC1(this.caddyGroupSelected)
            }
          }
        })
    },
    addNewCaddyGroup_ICAG01_AC2() {
      // Thêm mới tổ caddy
      this.$refs.detailCaddyGroup.validate().then(success => {
        if (success) {
          caddySetting
            .addNewCaddyGroup({ CaddyGroup: { CaddyGroupName: this.detailCaddyGroup.CaddyGroupName } })
            .then(res => {
              this.showResToast(res)
              if (res.Status == 200) {
                // Ẩn form thêm mới
                this.isOpenCaddyGroupDetail = false
                // Reset form detail caddy group
                this.detailCaddyGroup = {}
                // Cập nhật lại danh sách tổ caddy
                this.getListCaddyGroup()
              }
            })
        }
      })
    },
    async getDetailCaddyGroup_RCAG01_AC3(item) {
      // Hiện form sửa caddy
      this.isOpenCaddyGroupDetail = true
      this.detailCaddyGroup = JSON.parse(JSON.stringify(item))
      // Lấy thông tin chi tiết tổ caddy
      // await caddySetting
      //   .getDetailCaddyGroup({ CaddyGroup: { Id: caddyGroupId } })
      //   .then(res => {
      //     if (res.Status == 200) {
      //       this.detailCaddyGroup = res.Data.CaddyGroup
      //     }
      //   })
    },
    updateCaddyGroup_UCAG01_AC4() {
      // Cập nhật tổ tổ caddy
      this.$refs.detailCaddyGroup.validate().then(success => {
        if (success) {
          caddySetting
            .updateCaddyGroup({ CaddyGroup: this.detailCaddyGroup })
            .then(res => {
              this.showResToast(res)
              if (res.Status == 200) {
                // Ẩn form cập nhật
                this.isOpenCaddyGroupDetail = false
                // Reset form detail caddy group
                this.detailCaddyGroup = {}
                // Cập nhật lại danh sách tổ caddy
                this.getListCaddyGroup()
              }
            })
        }
      })
    },
    afterUpdateDetailCaddy() {
      // Nếu đang tìm kiếm. sau khi tìm kiếm
      if (this.searchObj.KeyWord || this.searchObj.Age || this.searchObj.CourseId || this.searchObj.SexCode) {
        this.searchCaddy_RCA01_AC3()
      } else {
      // Load lại danh sách caddy
        this.getListCaddyGroup()
        // Load lại danh sách caddy trong tổ
        this.getListCaddy_RCA01_AC1(this.caddyGroupSelected)
      }
    },
    async deactiveCaddyGroup_UCAG02_AC5(caddyGroupId) {
      // Ngừng hoạt động tổ caddy
      await caddySetting
        .deactiveCaddyGroup({ CaddyGroup: { Id: caddyGroupId.Id, IsForce: false } })
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.getListCaddyGroup(false)
          }
        })
    },
    async activeCaddyGroup_UCAG02_AC6(caddyGroupId) {
      // Hoạt động trở lại tổ caddy
      await caddySetting
        .activeCaddyGroup({ CaddyGroup: { Id: caddyGroupId.Id } })
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.getListCaddyGroup(false)
          }
        })
    },
    async deleteCaddyGroup_DCAG01_AC7(caddyGroupId) {
      // Xoá tổ caddy
      await caddySetting
        .deleteCaddyGroup({ CaddyGroup: { Id: caddyGroupId } })
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.getListCaddyGroup()
          }
        })
    },
    async getListCaddy_RCA01_AC1(groupId) {
      this.isLoading = true
      // reset tìm kiếm
      this.searchObj = {
        KeyWord: null,
        Age: null,
        CourseId: null,
        SexCode: null,
      }
      // Set caddy group hiện tại
      this.caddyGroupSelected = groupId
      // Lấy danh sách caddy của group
      await caddySetting
        .getListCaddy({ CaddyGroup: { Id: groupId } })
        .then(res => {
          if (res) {
            this.listCaddy = res.Data.Caddy
          }
        })
      this.isLoading = false
    },
    async searchCaddy_RCA01_AC3() {
      this.isLoading = true
      // Bỏ active group hiện tại
      this.caddyGroupSelected = null
      const body = {
        KeyWord: this.searchObj.KeyWord,
        Age: this.searchObj.Age,
        SexCode: this.searchObj.SexCode,
        CourseId: this.searchObj.CourseId,
      }
      await caddySetting
        .searchCaddy(body)
        .then(res => {
          if (res) {
            this.listCaddy = res.Data.Caddy
          }
        })
      this.isLoading = false
    },
    getShortName(name) {
      const temp = name.split(' ')
      return temp[temp.length - 1].charAt(0) + temp[temp.length - 1].charAt(1)
    },
    detailCaddy(val) {
      this.getListCaddy_RCA01_AC1(val)
    },
    async deleteCaddy_DCA01_AC8(caddyId) {
      // Xoá caddy
      await caddySetting
        .deleteCaddy({ Caddy: { Id: caddyId.Id } })
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.isOpen = false
            this.afterUpdateDetailCaddy(res)
          }
        })
    },
    async deactiveCaddy_UCA01_AC6(caddyId) {
      this.dataConfirm = {
        title: this.$t('golf_golf_caddy_deactive'),
        content: this.$t('golf_golf_caddy_confirm_deactive'),
      }
      this.statusAction = 'DEACTIVE'
      this.$refs.confirmDialogComponent.open({
        Id: caddyId,
        dataConfirm: this.dataConfirm,
      })
      // Ngừng hoạt động Caddy
    },
    activeCaddy_UCA01_AC7(caddyId) {
      this.dataConfirm = {
        title: this.$t('golf_golf_caddy_active'),
        content: this.$t('golf_golf_caddy_confirm_active'),
      }
      this.statusAction = 'ACTIVE'
      this.$refs.confirmDialogComponent.open({
        Id: caddyId,
        dataConfirm: this.dataConfirm,
      })
    },
    async actionDialog(data) {
      if (this.statusAction === 'ACTIVE') {
        await caddySetting
          .activeCaddy({ Caddy: { Id: data.Id } })
          .then(res => {
            this.showResToast(res)
            if (res.Status == 200) {
              this.isOpen = false
              this.afterUpdateDetailCaddy(res)
            }
          })
      } else {
        await caddySetting
          .deactiveCaddy({ Caddy: { Id: data.Id, IsForce: false } })
          .then(res => {
            this.showResToast(res)
            if (res.Status == 200) {
              this.isOpen = false
              this.afterUpdateDetailCaddy(res)
            }
          })
      }
    },
    GenerateQr() {
      this.listCaddyGenerateQr = this.listCaddy.filter(x => x.check)
      this.$nextTick(() => {
        setTimeout(() => {
          this.exportPDF()
        }, 2000)
      })
    },
    exportPDF() {
      this.listHTMLGenerateQr = []
      this.listCaddyGenerateQr.forEach(async (x, index) => {
        const fontEmbedCss = await htmlToImage.getFontEmbedCSS(document.getElementById(`print-image${index}`))
        htmlToImage.toJpeg(document.getElementById(`print-image${index}`), { quality: 1.0, fontEmbedCss,backgroundColor: '#fff'  })
        .then(function (dataUrl) {
          var link = document.createElement('a');
          link.download = `${x.CaddyCode}.jpeg`;
          link.href = dataUrl;
          link.click();
          
        })
        if(index == this.listCaddyGenerateQr.length - 1) {
          document.getElementById(`print-image`).remove()
        }
      });
      const divContents = document.getElementById(`print-tem`).innerHTML
        const data = `<html><head><title>ezCloud_ezGolf</title> <meta charset="UTF-8">
            </head><body style="padding: 0px;margin: 0px;">
            <style >  @media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} p{margin:0;}} </style>
            ${divContents}
            </body></html>
            `
            this.listHTMLGenerateQr.push({
              CaddyCode: 'Caddy: ' + this.listCaddyGenerateQr.map(x => x.CaddyCode).join(','),
              Html: data
            })
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     const data = this.$refs.html2Pdf
      //     data.forEach(z => {
      //       z.generatePdf()
      //     })
      //   }, 2000)
      // })

    },
    SelectAllCaddy() {
      this.SelectAll = !this.SelectAll
      this.listCaddy.forEach(x => {
        x.check = this.SelectAll
      })
      this.$forceUpdate()
    },
    generateXLSX() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Format Caddie");
      const worksheetSexCode = workbook.addWorksheet("SexCode");
      const worksheetPosition = workbook.addWorksheet("Position");
      const worksheetMaritalStatus = workbook.addWorksheet("MaritalStatus");
      const worksheetContract = workbook.addWorksheet("Contract");
      const worksheetBloodGroup = workbook.addWorksheet("BloodGroup");
      const worksheetClotherSize = workbook.addWorksheet("ClotherSize");
      const worksheetFormatDate = workbook.addWorksheet("FormatDate");
      
      worksheetFormatDate.columns = [
        { header: "Format Date", key: "key"},
      ];
      worksheetFormatDate.addRow({key: '01/01/2001'})


      worksheetSexCode.columns = [
        { header: "Mã giới tính", key: "id"},
        { header: "Giới tính", key: "label"},
      ];
      this.listSexTree.forEach(x => {
        worksheetSexCode.addRow(x)
      })


      worksheetPosition.columns = [
        { header: "Mã vị trí", key: "KeyCode"},
        { header: "vị trí", key: "KeyValue"},
      ];
      this.listCaddyPosition.forEach(x => {
        worksheetPosition.addRow(x)
      })

      worksheetMaritalStatus.columns = [
        { header: "Mã tình trạng hôn nhân", key: "KeyCode"},
        { header: "Tình trạng hôn nhân", key: "KeyValue"},
      ];
      this.listMaritalStatus.forEach(x => {
        worksheetMaritalStatus.addRow(x)
      })


      worksheetContract.columns = [
        { header: "Mã hợp đồng", key: "KeyCode"},
        { header: "Hợp đồng", key: "KeyValue"},
      ];
      this.listTypeOfContract.forEach(x => {
        worksheetContract.addRow(x)
      })

      worksheetBloodGroup.columns = [
        { header: "Nhóm máu", key: "KeyCode"},
      ];
      this.bloodList.forEach(x => {
        worksheetBloodGroup.addRow({
          KeyCode: x
        })
      })

      worksheetClotherSize.columns = [
        { header: "Size", key: "KeyCode"},
      ];
      this.listClotherSize.forEach(x => {
        worksheetClotherSize.addRow({
          KeyCode: x
        })
      })


      worksheet.columns = [
        { header: "Mã caddy", key: "CaddyCode"},
        { header: "Tên caddy", key: "CaddyName"},
        { header: "Ngày sinh", key: "BirthDay"},
        { header: "Số điện thoại", key: "PhoneNumber"},
        { header: "Số điện thoại người thân", key: "RelativePhoneNumber"},
        { header: "Email", key: "Email"},
        { header: "Địa chỉ 1", key: "Address1"},
        { header: "Địa chỉ 2", key: "Address2"},
        { header: "Ghi chú", key: "Note"},
        { header: "Chiều cao", key: "Height"},
        { header: "Cân nặng", key: "Weight"},
        { header: "Nhóm máu", key: "BloodGroup"},
        { header: "Size giày", key: "ShoeSize"},
        { header: "Size áo", key: "ShirtSize"},
        { header: "Size quần", key: "PantsSize"},
        { header: "Mã tủ locker", key: "LockerNumber"},
        { header: "Giới tính", key: "SexCode"},
        { header: "Tình trạng hôn nhân", key: "MaritalStatusCode"},
        { header: "Vị trí", key: "PositionCode"},
        { header: "Hợp đồng", key: "ContractCode"},
        { header: "UserId", key: "UserId"},
    ];
      workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `TemplateCaddyExport.xlsx`)
      })
    },
    triggerClickChooFiles() {
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.click();
    },
    async readFileExcel() {
      await readXlsxFile(this.$refs.choosefileExcel.files[0]).then(async (rows) => {
        var data = []
        var indexError = []
          rows.forEach((item,index) => {
            if(index != 0) {
              if(item[0] && item[1] && item[2] && item[3] && item[19] && item[18]) {
                if(this.validateForm(item[2])) {
                  data.push({
                    CaddyCode: item[0],
                    CaddyName: item[1],
                    BirthDay: this.convertToUTC0(new Date(item[2].split('/')[2],item[2].split('/')[1] - 1,item[2].split('/')[0],0,0,0)),
                    PhoneNumber: item[3] ? String(item[3]) : null,
                    RelativePhoneNumber: item[4] ? String(item[4]) : null,
                    Email: item[5],
                    Address1: item[6],
                    Address2: item[7],
                    Note: item[8],
                    Height: item[9] ? String(item[9]) : null,
                    Weight: item[10] ? String(item[10]) : null,
                    BloodGroup: item[11],
                    ShoeSize: item[12],
                    ShirtSize: item[13],
                    PantsSize: item[14],
                    LockerNumber: item[15] ? String(item[15]) : null,
                    SexCode: item[16],
                    MaritalStatusCode: item[17],
                    PositionCode: item[18],
                    ContractCode: item[19],
                    UserId: item[20],
                    CourseId: this.listCourse.map(course => course.id),
                    InActive: false,
                    CaddyGroupId: this.caddyGroupSelected
                  })
                } else {
                  indexError.push(index + 1)
                }
              } else {
                indexError.push(index + 1)
              }
            }
          })
          if(indexError.length > 0) {
            this.showToast('error', `Vui lòng kiểm tra lại với dòng ${indexError.join()}.Yêu cầu nhập đủ mã caddy, tên caddy, ngày sinh, số điện thoại, hợp đồng, vị trí và đúng định dạng ngày tháng.`)
          } else {
            if(data.length > 0) {
              this.isLoading = true
              for(let i = 0; i < data.length; i++) {
                await caddySetting
                  .addNewCaddy({ Caddy: data[i] })
                if(i == data.length - 1) {
                  this.afterUpdateDetailCaddy()
                }
              }
            }
          }
      });
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.value = ""
    },
    validateForm(input){
      var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
      if (input.match(reg)) {
        return true
      }
      else {
        return false
      }
    },
    convertToUTC0(date) {
      const timeZone = localStorage.getItem('timeZone')
      const offset = this.dayjs().utc().tz(timeZone).startOf('day')
        .utcOffset()
      return this.dayjs(date)
        .add(-offset, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss')
    },
  },
}
